import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Paths } from '../../app/configs/routePaths';
import { refreshTokenHandler } from '../../app/handlers/authHandlers';
import { useUserStore } from '../../app/store/userStore';
import { ReactComponent as LogoMpo } from '../../assets/logoMPO.svg';
import { ReactComponent as LogoMpoText } from '../../assets/logoMpoText.svg';

export const Auth = () => {
  const isAuth = useUserStore((state) => state.isAuth);
  const location = useLocation();

  const fromPage = location.state?.from.pathname || Paths.MAIN;

  useEffect(() => {
    refreshTokenHandler();
  }, []);

  if (isAuth) {
    return <Navigate to={fromPage} replace={true} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexGrow: 1,
        minHeight: '100vh',
        backgroundColor: '#161514'
      }}
    >
      <Container maxWidth={'sm'}>
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            backgroundColor: '#FAFAFA',
            borderRadius: 10
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
            <LogoMpo />
            <LogoMpoText />
          </Box>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
};
