import { Dayjs } from 'dayjs';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { IFilter } from '../models/filters';
import { ITransaction, ITransactionsRes } from '../models/response/terminal';

type State = {
  page: number;
  pageSize: number;
  totalPages: number;
  transactions: ITransaction[] | [];
  isLoading: boolean;
  errorMessage: string;
  filters: IFilter;
};

type Actions = {
  setStateFromRes: (data: ITransactionsRes) => void;
  setPage: (page: number) => void;
  setIsLoading: (value: boolean) => void;
  setErrorMessage: (message: string) => void;
  resetStore: () => void;
  setDateFrom: (date: Dayjs | null) => void;
  setDateTo: (date: Dayjs | null) => void;
};

const initialFilters: IFilter = {
  dateFrom: null,
  dateTo: null
};

const initialState: State = {
  page: 1,
  pageSize: 50,
  totalPages: 1,
  transactions: [],
  isLoading: false,
  errorMessage: '',
  filters: initialFilters
};

export const useExpensesStore = create<State & Actions>()(
  devtools(
    (set) => ({
      ...initialState,
      setStateFromRes: (data) => {
        set({
          page: data.page,
          pageSize: data.page_size,
          totalPages: data.total_pages,
          transactions: data.transactions
        });
      },
      setPage: (page: number) => {
        set({ page: page });
      },
      setIsLoading: (value: boolean) => {
        set({ isLoading: value });
      },
      setErrorMessage: (message: string) => {
        set({ errorMessage: message });
      },
      setDateFrom: (date) =>
        set((state) => ({
          filters: {
            ...state.filters,
            dateFrom: date
          }
        })),
      setDateTo: (date) => {
        set((state) => ({
          filters: {
            ...state.filters,
            dateTo: date
          }
        }));
      },
      resetStore: () => {
        set(initialState);
      }
    }),
    { name: 'ExpensesStore' }
  )
);
