import AddIcon from '@mui/icons-material/Add';
import { Alert, Box, Button, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { getTeamMembersHandler } from '../../app/handlers/terminalHandlers';
import { useTeamMembersStore } from '../../app/store/teamMembersStore';
import { useUserStore } from '../../app/store/userStore';
import { InviteMemberDialog } from '../Modals/InviteMemberDialog';
import { TableTeam } from '../Tables/TableTeam';

export const TeamBlock = () => {
  const [members, isLoading, errorMessage] = useTeamMembersStore((state) => [
    state.members,
    state.isLoading,
    state.errorMessage
  ]);
  const terminal_uuid = useUserStore((state) => state.terminal_uuid);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getTeamMembersHandler();
  }, [terminal_uuid]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} alignItems={'center'} mb={3}>
        <Typography variant="h5" component="h2" flexGrow={1}>
          Команда
        </Typography>
        <Button
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
        >
          Пригласить
        </Button>
        <InviteMemberDialog open={openDialog} closeDialog={handleClose} />
      </Box>

      <Paper sx={{ paddingY: 4 }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {members.length > 0 && <TableTeam members={members} />}
      </Paper>
    </Box>
  );
};
