import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Paths } from '../../app/configs/routePaths';
import { signUpHandler } from '../../app/handlers/authHandlers';
import { ISignUpForm } from '../../app/models/formFields';

const defaultFormFields: ISignUpForm = {
  email: '',
  password: '',
  password_confirm: '',
  phone: ''
};

export const SignUp = () => {
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { email, password, password_confirm, phone } = formFields;

  const changeFormField = (e: ChangeEvent<HTMLInputElement>) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await signUpHandler(formFields, setIsLoading, setErrorMessage);

    //result && navigate(Paths.MAIN);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        autoFocus
        value={email}
        onChange={changeFormField}
        size={'medium'}
      />
      <FormControl variant="outlined" fullWidth margin="normal" required>
        <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          name="password"
          autoComplete="new-password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={changeFormField}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </FormControl>
      <FormControl variant="outlined" fullWidth margin="normal" required>
        <InputLabel htmlFor="confirm-password">Подтвердите пароль</InputLabel>
        <OutlinedInput
          id="password-confirm"
          name="password_confirm"
          autoComplete="new-password"
          type={showPassword ? 'text' : 'password'}
          value={password_confirm}
          onChange={changeFormField}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Confirm-password"
        />
      </FormControl>
      <TextField
        margin="normal"
        required
        fullWidth
        id="phone"
        label="Номер телефона"
        name="phone"
        autoComplete="phone"
        value={phone}
        onChange={changeFormField}
        size={'medium'}
      />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <LoadingButton
        loading={isLoading}
        type="submit"
        fullWidth
        variant="contained"
        color="secondary"
        size="large"
        sx={{ mt: 2, mb: 2 }}
      >
        <span>Зарегистрироваться</span>
      </LoadingButton>
      <Grid container>
        <Grid item xs>
          {/*<Link
            component="button"
            variant="body2"
            underline="hover"
            onClick={() => navigate('../' + Paths.RESET_PASSWORD)}
          >
            Забыли пароль?
          </Link>*/}
        </Grid>
        <Grid item>
          <Link
            component="button"
            variant="body2"
            underline="hover"
            onClick={() => navigate(Paths.AUTH)}
          >
            Войти
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
