import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import React from 'react';

import { ITerminal } from '../../app/models/response/terminal';

interface Props {
  options: ITerminal[];
  value: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none' | undefined;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const TerminalSelector = ({
  options,
  value,
  fullWidth,
  margin,
  required,
  error,
  disabled,
  onChange
}: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      required={required}
      error={error}
      disabled={disabled}
      sx={{ minWidth: 130, maxWidth: 200 }}
    >
      <InputLabel size="small">Терминал</InputLabel>
      <Select value={value} label="Терминал" onChange={handleChange} fullWidth>
        {options.map((option) => (
          <MenuItem key={option.uuid} value={option.uuid}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
