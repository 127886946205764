import { AxiosResponse } from 'axios';

import { apiInstance } from '../api';
import {
  IDashboardInfo,
  IPayoutRes,
  ISecretRes,
  ISettingsRes,
  ITeamRes,
  ITerminalListRes,
  ITransactionsRes
} from '../models/response/terminal';

export default class TerminalService {
  static async getList(): Promise<AxiosResponse<ITerminalListRes>> {
    return apiInstance.get('/terminal/list');
  }

  static async getInfo(
    terminal_uuid: string
  ): Promise<AxiosResponse<IDashboardInfo>> {
    return apiInstance.get(`/terminal/${terminal_uuid}`);
  }

  static async getSettings(
    terminal_uuid: string
  ): Promise<AxiosResponse<ISettingsRes>> {
    return apiInstance.get(`/terminal/${terminal_uuid}/settings`);
  }

  static async setSettings(
    terminal_uuid: string,
    testing_mode: boolean
  ): Promise<AxiosResponse> {
    return apiInstance.post(`/terminal/${terminal_uuid}/settings`, {
      testing_mode
    });
  }

  static async getSecret(
    terminal_uuid: string
  ): Promise<AxiosResponse<ISecretRes>> {
    return apiInstance.get(`/terminal/${terminal_uuid}/secret`);
  }

  static async getExpenses(
    terminal_uuid: string,
    page?: number,
    from?: string,
    to?: string
  ): Promise<AxiosResponse<ITransactionsRes>> {
    return apiInstance.get(
      `/terminal/${terminal_uuid}/expenses?${page ? '&page=' + page : ''}${from ? '&from=' + from : ''}${to ? '&to=' + to : ''}`
    );
  }

  static async getIncomes(
    terminal_uuid: string,
    page?: number,
    from?: string,
    to?: string
  ): Promise<AxiosResponse<ITransactionsRes>> {
    return apiInstance.get(
      `/terminal/${terminal_uuid}/incomes?${page ? '&page=' + page : ''}${from ? '&from=' + from : ''}${to ? '&to=' + to : ''}`
    );
  }

  static async getMembers(
    terminal_uuid: string
  ): Promise<AxiosResponse<ITeamRes>> {
    return apiInstance.get(`/terminal/${terminal_uuid}/team`);
  }

  static async inviteMember(
    terminal_uuid: string,
    email: string,
    role: string
  ): Promise<AxiosResponse> {
    return apiInstance.post(`/terminal/${terminal_uuid}/invite`, {
      email,
      role
    });
  }

  static async removeMember(
    terminal_uuid: string,
    email: string,
    role: string
  ): Promise<AxiosResponse> {
    return apiInstance.post(`/terminal/${terminal_uuid}/remove`, {
      email,
      role
    });
  }

  static async payout(
    terminal_uuid: string,
    amount: number,
    beneficiary: string,
    currency: string,
    pan: string
  ): Promise<AxiosResponse<IPayoutRes>> {
    return apiInstance.post(`/terminal/${terminal_uuid}/payout`, {
      amount,
      beneficiary,
      currency,
      pan
    });
  }
}
