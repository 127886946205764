import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    failed: Palette['primary'];
    pending: Palette['primary'];
  }

  interface PaletteOptions {
    failed?: PaletteOptions['primary'];
    pending?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    failed: true;
    pending: true;
  }
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#161514',
      light: '#2c2c2c',
      dark: '#000000',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#FF9704',
      contrastText: '#FFF'
    },
    success: {
      main: '#4DB6AC',
      contrastText: '#FFF'
    },
    failed: {
      main: '#E57373',
      contrastText: '#FFF'
    },
    pending: {
      main: '#FFB74D',
      contrastText: '#FFF'
    }
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('sm')]: {
            minHeight: '80px'
          }
        })
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '0px 16px 16px 0px',
          height: 64,
          position: 'relative',
          transition: 'all .4s',
          boxShadow: 'none',
          textTransform: 'none',
          /*'&:before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: 0,
            height: '100%',
            backgroundColor: '#e1e1e1',
            color: theme.palette.secondary.contrastText,
            borderRadius: '0px 16px 16px 0px',
            transition: 'all .4s',
            boxShadow: 'none'
          },*/
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
            /*':hover': {
              backgroundColor: theme.palette.primary.main
            },
            ':focus': {
              backgroundColor: theme.palette.primary.main
            }*/
          }
          /*':hover': {
            backgroundColor: 'transparent',
            ':before': {
              width: '100%'
            }
          },
          ':focus': {
            backgroundColor: 'transparent',
            ':before': {
              width: '100%',
              backgroundColor: '#e1e1e1'
            }
          }*/
        })
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('sm')]: {
            padding: '16px !important'
          },
          [theme.breakpoints.up('md')]: {
            padding: '24px !important'
          },
          [theme.breakpoints.up('lg')]: {
            padding: '32px !important'
          }
        })
      }
    },
    MuiInputBase: {
      defaultProps: {
        color: 'secondary'
      },
      styleOverrides: {
        root: {}
      }
    },
    MuiInput: {
      styleOverrides: {}
    },
    MuiOutlinedInput: {
      styleOverrides: {}
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {}
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none'
        },
        sizeLarge: {
          padding: '16px 22px'
        },
        sizeMedium: {},
        sizeSmall: {
          padding: '2px 12px'
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {}
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {}
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary'
      },
      styleOverrides: {
        root: {}
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {}
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {}
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {}
      }
    },
    MuiMenu: {
      styleOverrides: {}
    },
    MuiAlert: {
      styleOverrides: {
        root: {}
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {}
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {}
      }
    }
  }
});
