import { CssBaseline } from '@mui/material';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from './app/configs/routerConfig';
import { MuiThemeProvider } from './app/providers/themeProvider/ThemeProvider';

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <CssBaseline />
      <MuiThemeProvider>
        <Suspense fallback={''}>
          <RouterProvider router={router} />
        </Suspense>
      </MuiThemeProvider>
    </React.StrictMode>
  );
}
