import { Box, Typography } from '@mui/material';
import React from 'react';

export const WelcomeScreen = () => {
  return (
    <Box flexGrow={1} justifyContent="center" alignItems="center">
      <Typography variant="h3" component="h1" textAlign="center">
        Welcome!
      </Typography>
    </Box>
  );
};
