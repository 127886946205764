import {
  Box,
  FormControlLabel,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';

import { changeTerminalSettingsHandler } from '../../app/handlers/terminalHandlers';
import { useUserStore } from '../../app/store/userStore';
import { TeamBlock } from '../../components/Team/TeamBlock';
import { SecretKeyInput } from '../../components/UI/Inputs/SecretKeyInput';

export const Profile = () => {
  const [isTestMode, setTestMode, terminal_uuid, secret] = useUserStore(
    (state) => [
      state.isTestMode,
      state.setTestMode,
      state.terminal_uuid,
      state.secret
    ]
  );

  const handleChangeTestMode = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked;
    const result = await changeTerminalSettingsHandler(value);

    result && setTestMode(value);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={6}>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
          Профиль терминала
        </Typography>
        <Paper sx={{ padding: 4 }}>
          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
            <SecretKeyInput value={secret} terminal={terminal_uuid} />
            <TextField
              disabled
              size="medium"
              id="filled-disabled"
              label="Идентификатор терминала"
              value={terminal_uuid}
              variant="filled"
              sx={{ width: '35ch' }}
            />
          </Box>
          <Tooltip title="Тестовый режим запрещает оплату реальными картами">
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Switch
                  checked={isTestMode}
                  onChange={handleChangeTestMode}
                  color="secondary"
                />
              }
              label="Активировать тестовый режим"
            />
          </Tooltip>
        </Paper>
      </Box>
      <TeamBlock />
    </Box>
  );
};
