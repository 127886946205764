import { AxiosResponse } from 'axios';

import { apiInstance, authInstance } from '../api';
import { AuthResponse } from '../models/response/auth';

export default class AuthService {
  static async register(
    email: string,
    password: string,
    password_confirm: string,
    phone: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return authInstance.post('/auth/register', {
      email,
      password,
      password_confirm,
      phone
    });
  }

  static async authenticate(
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return authInstance.post('/auth/authenticate', {
      email,
      password
    });
  }

  static async refreshToken(
    refresh_token: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return authInstance.post('/auth/refresh', {
      refresh_token
    });
  }
}
