import axios from 'axios';

import { IFilter } from '../models/filters';
import { IDashboardInfo } from '../models/response/terminal';
import TerminalService from '../services/terminalService';
import { useExpensesStore } from '../store/expensesStore';
import { useIncomesStore } from '../store/incomesStore';
import { useTeamMembersStore } from '../store/teamMembersStore';
import { useUserStore } from '../store/userStore';

const setTerminals = useUserStore.getState().setTerminals;

export const getExpensesHandler = async (
  page: number,
  filters: IFilter,
  setIsLoading: (value: boolean) => void,
  setErrorMessage: (message: string) => void
) => {
  const setStateFromRes = useExpensesStore.getState().setStateFromRes;
  const terminal_uuid = useUserStore.getState().terminal_uuid;

  setErrorMessage('');
  setIsLoading(true);

  try {
    const { data } = await TerminalService.getExpenses(
      terminal_uuid,
      page,
      filters.dateFrom?.toJSON() || '',
      filters.dateTo?.toJSON() || ''
    );

    setStateFromRes(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const getIncomesHandler = async (
  page: number,
  filters: IFilter,
  setIsLoading: (value: boolean) => void,
  setErrorMessage: (message: string) => void
) => {
  const setStateFromRes = useIncomesStore.getState().setStateFromRes;
  const terminal_uuid = useUserStore.getState().terminal_uuid;
  setErrorMessage('');
  setIsLoading(true);

  try {
    const { data } = await TerminalService.getIncomes(
      terminal_uuid,
      page,
      filters.dateFrom?.toJSON() || '',
      filters.dateTo?.toJSON() || ''
    );

    setStateFromRes(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const getTeamMembersHandler = async () => {
  const { setStateFromRes, setIsLoading, setErrorMessage } =
    useTeamMembersStore.getState();
  const terminal_uuid = useUserStore.getState().terminal_uuid;
  setErrorMessage('');
  setIsLoading(true);
  try {
    const { data } = await TerminalService.getMembers(terminal_uuid);

    setStateFromRes(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const addMemberToTerminal = async (
  email: string,
  role: string,
  setIsLoading: (value: boolean) => void,
  setErrorMessage: (message: string) => void
) => {
  const terminal_uuid = useUserStore.getState().terminal_uuid;
  setErrorMessage('');
  setIsLoading(true);

  try {
    await TerminalService.inviteMember(terminal_uuid, email, role);

    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const removeMemberFromTerminal = async (
  email: string,
  role: string,
  setIsLoading: (value: boolean) => void,
  setErrorMessage: (message: string) => void
) => {
  const terminal_uuid = useUserStore.getState().terminal_uuid;
  setErrorMessage('');
  setIsLoading(true);

  try {
    await TerminalService.removeMember(terminal_uuid, email, role);

    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const getTerminalsListHandler = async (
  setIsLoading: (value: boolean) => void,
  setErrorMessage: (message: string) => void
) => {
  setErrorMessage('');
  setIsLoading(true);

  try {
    const { data } = await TerminalService.getList();

    setTerminals(data.terminals);

    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const getDashboardInfoHandler = async (
  setData: (data: IDashboardInfo) => void,
  setIsLoading: (value: boolean) => void,
  setErrorMessage: (message: string) => void
) => {
  const terminal_uuid = useUserStore.getState().terminal_uuid;
  setErrorMessage('');
  setIsLoading(true);

  try {
    const { data } = await TerminalService.getInfo(terminal_uuid);

    setData(data);

    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const getSecretKeyHandler = async (
  setErrorMessage: (message: string) => void
) => {
  const { terminal_uuid, setSecret } = useUserStore.getState();
  setErrorMessage('');

  try {
    const { data } = await TerminalService.getSecret(terminal_uuid);

    setSecret(data.secret);

    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  }
};

export const createPayoutHandler = async (
  amount: number,
  beneficiary: string,
  currency: string,
  pan: string,
  setIsLoading: (value: boolean) => void,
  setErrorMessage: (message: string) => void
) => {
  const terminal_uuid = useUserStore.getState().terminal_uuid;
  setErrorMessage('');
  setIsLoading(true);

  try {
    const { data } = await TerminalService.payout(
      terminal_uuid,
      amount,
      beneficiary,
      currency,
      pan
    );

    console.log(data);
    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const getTerminalSettingsHandler = async () => {
  const { terminal_uuid, setTestMode } = useUserStore.getState();

  try {
    const { data } = await TerminalService.getSettings(terminal_uuid);
    setTestMode(data.testing_mode);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.response?.data.message);
    } else {
      console.log(error);
    }
  }
};

export const changeTerminalSettingsHandler = async (testing_mode: boolean) => {
  const terminal_uuid = useUserStore.getState().terminal_uuid;

  try {
    await TerminalService.setSettings(terminal_uuid, testing_mode);

    return true;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.response?.data.message);
    } else {
      console.log(error);
    }
  }
};
