import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const HomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
        <path
          d="M20.0062 10.275L12.5062 3.45933C12.368 3.33284 12.1874 3.2627 12 3.2627C11.8126 3.2627 11.632 3.33284 11.4937 3.45933L3.99375 10.275C3.91828 10.3462 3.85783 10.4319 3.81597 10.5269C3.77411 10.6219 3.75168 10.7243 3.75 10.8281V19.5C3.75 19.6989 3.82902 19.8896 3.96967 20.0303C4.11032 20.1709 4.30109 20.25 4.5 20.25H19.5C19.6989 20.25 19.8897 20.1709 20.0303 20.0303C20.171 19.8896 20.25 19.6989 20.25 19.5V10.8281C20.2483 10.7243 20.2259 10.6219 20.184 10.5269C20.1422 10.4319 20.0817 10.3462 20.0062 10.275V10.275Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};
