import { Roles } from '../../configs/roles';

export enum ITransactionStatus {
  FAILED = 'failed',
  SUCCESS = 'success',
  PENDING = 'pending'
}

export interface ITransaction {
  amount: number;
  created_at: string;
  currency: string;
  fee_amount: number;
  order_id: string;
  status: ITransactionStatus;
  total_amount: number;
}

export interface ITransactionsRes {
  page: number;
  page_size: number;
  total_pages: number;
  transactions: ITransaction[];
}

export interface ITeamMember {
  email: string;
  role: Roles;
  uuid: string;
}

export interface ITeamRes {
  members: ITeamMember[];
}

export interface ITerminal {
  name: string;
  role_name: string;
  uuid: string;
}

export interface ITerminalListRes {
  terminals: ITerminal[];
}

export interface IDashboardInfo {
  balance: number;
  payouts: number;
  purchases: number;
}

export interface ISecretRes {
  secret: string;
}

export interface IPayoutRes {
  order_id: string;
  status: string;
}

export interface ISettingsRes {
  testing_mode: boolean;
}
