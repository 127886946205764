import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React, { useState } from 'react';

import { Roles, rolesOptions } from '../../app/configs/roles';
import {
  addMemberToTerminal,
  getTeamMembersHandler
} from '../../app/handlers/terminalHandlers';
import { ITeamMember } from '../../app/models/response/terminal';
import { DeleteMemberDialog } from '../Modals/DeleteMemberDialog';
import { CustomSelector } from '../Selectors/CustomSelector';

interface UserRowProps {
  member: ITeamMember;
}

const UserRow = ({ member }: UserRowProps) => {
  const [role, setRole] = useState(member.role);
  const [selectLoading, setSelectLoading] = useState(false);
  const [selectError, setSelectError] = useState('');
  const [showDialog, setShowDialog] = useState(false);

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleChangeRole = async (value: string) => {
    setRole(value as Roles);

    const result = await addMemberToTerminal(
      member.email,
      member.role,
      setSelectLoading,
      setSelectError
    );

    if (result) {
      await getTeamMembersHandler();
    } else {
      setRole(member.role);
    }
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
      <TableCell
        component="th"
        scope="row"
        sx={{
          paddingLeft: 4
        }}
      >
        {member.email}
      </TableCell>
      <TableCell
        sx={{
          maxWidth: 150,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {member.uuid}
      </TableCell>
      <TableCell>
        <CustomSelector
          options={rolesOptions}
          value={role}
          onChange={handleChangeRole}
          error={!!selectError}
          fullWidth={true}
        />
      </TableCell>
      <TableCell align="right" sx={{ paddingRight: 4 }}>
        <IconButton onClick={handleOpenDialog}>
          <DeleteOutlinedIcon />
        </IconButton>
        <DeleteMemberDialog
          member={member}
          open={showDialog}
          closeDialog={handleCloseDialog}
        />
      </TableCell>
    </TableRow>
  );
};

interface TableTeamProps {
  members: ITeamMember[];
}

export const TableTeam = ({ members }: TableTeamProps) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                paddingLeft: 4
              }}
            >
              Email
            </TableCell>
            <TableCell>uuid</TableCell>
            <TableCell>Доступ</TableCell>
            <TableCell align="right" sx={{ paddingRight: 4 }}>
              Действиe
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((member) => (
            <UserRow key={member.uuid} member={member} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
