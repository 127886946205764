import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';

import { IPayoutRes } from '../../app/models/response/terminal';

interface Props {
  open: boolean;
  closeDialog: () => void;
  isLoading: boolean;
  errorMessage: string;
  result: IPayoutRes | null;
}

export const PayoutDialog = ({
  open,
  closeDialog,
  isLoading,
  errorMessage,
  result
}: Props) => {
  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="xs" fullWidth>
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 4
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      )}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {result && (
        <>
          <DialogTitle>Успешно</DialogTitle>
          <DialogContent sx={{ pb: 0 }}>
            <DialogContentText>order_id: {result.order_id}</DialogContentText>
            <DialogContentText>status: {result.status}</DialogContentText>
          </DialogContent>
        </>
      )}

      <DialogActions
        sx={{
          justifyContent: 'center'
        }}
      >
        <Button onClick={closeDialog}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};
