import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { SvgIconProps } from '@mui/material';

import {
  ClientsIcon,
  HomeIcon,
  PaymentsIcon,
  ProfileIcon,
  WithdrawalsIcon
} from '../../components/UI/Icons';
import { Paths } from './routePaths';

export interface ISidebarLink {
  text: string;
  to: string;
  Icon: (props: SvgIconProps) => ReactJSXElement;
}

export const sidebarLinks: ISidebarLink[] = [
  {
    text: 'Главная',
    to: Paths.DASHBOARD,
    Icon: HomeIcon
  },
  {
    text: 'Платежи',
    to: Paths.INCOMES,
    Icon: PaymentsIcon
  },
  {
    text: 'Выплаты',
    to: Paths.EXPENSES,
    Icon: WithdrawalsIcon
  },
  /*  {
    text: 'Клиенты',
    to: Paths.CLIENTS,
    Icon: ClientsIcon
  },*/
  {
    text: 'Профиль',
    to: Paths.PROFILE,
    Icon: ProfileIcon
  }
];
