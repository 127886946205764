import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { ITeamMember, ITeamRes } from '../models/response/terminal';

type State = {
  members: ITeamMember[] | [];
  isLoading: boolean;
  errorMessage: string;
};

type Actions = {
  setStateFromRes: (data: ITeamRes) => void;
  setIsLoading: (value: boolean) => void;
  setErrorMessage: (message: string) => void;
  resetStore: () => void;
};

const initialState: State = {
  members: [],
  isLoading: false,
  errorMessage: ''
};

export const useTeamMembersStore = create<State & Actions>()(
  devtools(
    (set) => ({
      ...initialState,
      setStateFromRes: (data) => {
        set({
          members: data.members
        });
      },
      setIsLoading: (value: boolean) => {
        set({ isLoading: value });
      },
      setErrorMessage: (message: string) => {
        set({ errorMessage: message });
      },
      resetStore: () => {
        set(initialState);
      }
    }),
    { name: 'TeamMembersStore' }
  )
);
