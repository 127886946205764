import { Alert, Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { getDashboardInfoHandler } from '../../app/handlers/terminalHandlers';
import { IDashboardInfo } from '../../app/models/response/terminal';
import { useUserStore } from '../../app/store/userStore';
import { PayoutBlock } from '../../components/PayoutBlock/PayoutBlock';
import { DashboardCard } from '../../components/UI/DashboardCard/DashboardCard';

export const Dashboard = () => {
  const terminal_uuid = useUserStore((state) => state.terminal_uuid);
  const [data, setData] = useState<IDashboardInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const setDashboardData = async () => {
    await getDashboardInfoHandler(setData, setIsLoading, setErrorMessage);
  };

  useEffect(() => {
    setDashboardData();
  }, [terminal_uuid]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={6}>
      <Box>
        <Typography variant="h5" component="h2">
          Поступления
        </Typography>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {data && (
          <Grid container spacing={3} pt={3}>
            {Object.entries(data).map(([key, value]) => (
              <DashboardCard
                key={key}
                value={value}
                label={key}
                color={'#FEEFE2'}
              />
            ))}
          </Grid>
        )}
      </Box>

      <PayoutBlock setDashboardData={setDashboardData} />
    </Box>
  );
};
