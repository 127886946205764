import { Chip } from '@mui/material';
import React from 'react';

import { statusTypes } from '../../../app/configs/statuses';
import { ITransactionStatus } from '../../../app/models/response/terminal';

interface Props {
  status: ITransactionStatus;
}

export const StatusTable = ({ status }: Props) => {
  const item = statusTypes.get(status);

  const color = item?.value;

  return <Chip variant="filled" color={color} label={item?.label} />;
};
