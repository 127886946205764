import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import React, { useState } from 'react';

import { Roles, rolesOptions } from '../../app/configs/roles';
import { addMemberToTerminal } from '../../app/handlers/terminalHandlers';
import { CustomSelector } from '../Selectors/CustomSelector';

interface Props {
  open: boolean;
  closeDialog: () => void;
}

export const InviteMemberDialog = ({ open, closeDialog }: Props) => {
  const [role, setRole] = React.useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onHandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const email = formJson.email;

    const result = await addMemberToTerminal(
      email,
      role,
      setIsLoading,
      setErrorMessage
    );

    result && closeDialog();
  };

  const handleChangeRole = (value: string) => {
    setRole(value as Roles);
  };

  const closeDialogWithClearError = () => {
    setErrorMessage('');
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialogWithClearError}
      PaperProps={{
        component: 'form',
        onSubmit: onHandleSubmit
      }}
    >
      <DialogTitle>Пригласить</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText>Добавить члена команды в терминал</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
        />
        <CustomSelector
          options={rolesOptions}
          value={role}
          onChange={handleChangeRole}
          fullWidth={true}
          margin={'dense'}
          required={true}
        />
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'flex-start',
          paddingInline: 3,
          paddingBottom: 3
        }}
      >
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="secondary"
          type="submit"
          startIcon={<AddIcon />}
        >
          Пригласить
        </LoadingButton>
        <Button onClick={closeDialog}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
};
