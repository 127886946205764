import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export const ErrorPage = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <Container maxWidth="sm">
        <Box
          id="error-page"
          sx={{
            flexGrow: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 3
          }}
        >
          <Typography variant={'h2'} component="h1" mb={2}>
            Oops!
          </Typography>
          <Typography paragraph>
            Sorry, an unexpected error has occurred.
          </Typography>
          <Typography paragraph>
            <i>
              {error.status} {error.statusText}
            </i>
          </Typography>
        </Box>
      </Container>
    );
  } else if (error instanceof Error) {
    return (
      <Container maxWidth="sm">
        <Box
          id="error-page"
          sx={{
            flexGrow: 1,
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mt: 3
          }}
        >
          <Typography variant={'h2'} component="h1" mb={2}>
            Oops!
          </Typography>
          <Typography paragraph>
            Sorry, an unexpected error has occurred.
          </Typography>
          <Typography paragraph>
            <i>{error.message}</i>
          </Typography>
        </Box>
      </Container>
    );
  } else {
    return <></>;
  }
};
