import { Alert, Box, Toolbar } from '@mui/material';
import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Paths } from '../../app/configs/routePaths';
import { refreshTokenHandler } from '../../app/handlers/authHandlers';
import { getTerminalSettingsHandler } from '../../app/handlers/terminalHandlers';
import { useUserStore } from '../../app/store/userStore';
import { Header } from '../../components/Header/Header';
import { Sidebar } from '../../components/Sidebar/Sidebar';

export const Main = () => {
  const location = useLocation();
  const [isAuth, isTestMode, terminal_uuid] = useUserStore((state) => [
    state.isAuth,
    state.isTestMode,
    state.terminal_uuid
  ]);

  useEffect(() => {
    refreshTokenHandler();
  }, []);

  useEffect(() => {
    getTerminalSettingsHandler();
  }, [terminal_uuid]);

  if (!isAuth) {
    return <Navigate to={Paths.AUTH} state={{ from: location }} />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: 100,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Toolbar />
        {isTestMode && (
          <Alert severity="warning" sx={{ mb: 1 }}>
            Активен тестовый режим
          </Alert>
        )}
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            p: 5,
            backgroundColor: '#FAFAFA',
            borderRadius: 10
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
