import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

import { getSecretKeyHandler } from '../../../app/handlers/terminalHandlers';
import { AutohideSnackbar } from '../Snackbars/AutohideSnackbar';

interface Props {
  value: string;
  terminal: string;
}

export const SecretKeyInput = ({ value, terminal }: Props) => {
  const [showKey, setShowKey] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [copiedText, copy] = useCopyToClipboard();

  const handleClickShowKey = () => {
    setShowKey((prevState) => !prevState);
  };

  const handleMouseDownIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleCopyKey = () => {
    copy(value);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    getSecretKeyHandler(setErrorMessage);
  }, [terminal]);

  return (
    <FormControl variant="filled" sx={{ width: '35ch' }}>
      <InputLabel htmlFor="outlined-adornment-key" disabled>
        Секретный ключ
      </InputLabel>
      <FilledInput
        disabled
        id="outlined-adornment-key"
        value={value}
        type={showKey ? 'text' : 'password'}
        endAdornment={
          <>
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={handleClickShowKey}
                onMouseDown={handleMouseDownIcon}
              >
                {showKey ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={handleCopyKey}
                onMouseDown={handleMouseDownIcon}
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            </InputAdornment>
          </>
        }
      />
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      <AutohideSnackbar
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        text="Скопировано!"
      />
    </FormControl>
  );
};
