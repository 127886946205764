import { createBrowserRouter } from 'react-router-dom';

import {
  Auth,
  Clients,
  Dashboard,
  ErrorPage,
  Expenses,
  Incomes,
  Main,
  ResetPassword,
  SignIn,
  SignUp,
  WelcomeScreen
} from '../../routes';
import { Profile } from '../../routes/profile/profile';
import App from '../App';
import { Paths } from './routePaths';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: []
  },
  {
    path: Paths.AUTH,
    element: <Auth />,
    children: [
      {
        index: true,
        element: <SignIn />
      },
      {
        path: Paths.SIGN_UP,
        element: <SignUp />
      },
      {
        path: Paths.RESET_PASSWORD,
        element: <ResetPassword />
      }
    ]
  },
  {
    path: Paths.MAIN,
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <WelcomeScreen />
      },
      {
        path: Paths.DASHBOARD,
        element: <Dashboard />
      },
      {
        path: Paths.INCOMES,
        element: <Incomes />
      },
      {
        path: Paths.EXPENSES,
        element: <Expenses />
      },
      /*      {
        path: Paths.CLIENTS,
        element: <Clients />
      },*/
      {
        path: Paths.PROFILE,
        element: <Profile />
      }
    ]
  }
]);
