import { Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import React from 'react';

import { IFilter } from '../../app/models/filters';

interface Props {
  filters: IFilter;
  changeDateFrom: (value: Dayjs | null) => void;
  changeDateTo: (value: Dayjs | null) => void;
}

export const FilterBlock = ({
  filters,
  changeDateFrom,
  changeDateTo
}: Props) => {
  return (
    <Box px={4} display={'flex'} gap={2} width="100%">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DatePicker
          label="с даты"
          value={filters.dateFrom}
          onChange={changeDateFrom}
        />
        <DatePicker
          label="по дату"
          value={filters.dateTo}
          onChange={changeDateTo}
        />
      </LocalizationProvider>
    </Box>
  );
};
