import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ProfileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
        <path
          d="M14.25 10.5H18"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 13.5H18"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.63438 13.5C9.87702 13.5 10.8844 12.4926 10.8844 11.25C10.8844 10.0074 9.87702 9 8.63438 9C7.39174 9 6.38438 10.0074 6.38438 11.25C6.38438 12.4926 7.39174 13.5 8.63438 13.5Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.72813 15.75C5.89335 15.1047 6.26865 14.5327 6.79486 14.1243C7.32107 13.7158 7.96825 13.4941 8.63438 13.4941C9.3005 13.4941 9.94769 13.7158 10.4739 14.1243C11.0001 14.5327 11.3754 15.1047 11.5406 15.75"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
