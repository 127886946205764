import { LoadingButton } from '@mui/lab';
import { Box, Paper, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';

import { createPayoutHandler } from '../../app/handlers/terminalHandlers';
import { IPayoutForm } from '../../app/models/formFields';
import { IPayoutRes } from '../../app/models/response/terminal';
import { PayoutDialog } from '../Modals/PayoutDialog';

interface Props {
  setDashboardData: () => void;
}

const defaultFields: IPayoutForm = {
  amount: '',
  beneficiary: '',
  currency: 'RUB',
  pan: ''
};

export const PayoutBlock = ({ setDashboardData }: Props) => {
  const [formFields, setFormFields] = useState(defaultFields);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [result, setResult] = useState<IPayoutRes | null>(null);

  const { amount, beneficiary, currency, pan } = formFields;

  const changeFormField = (e: ChangeEvent<HTMLInputElement>) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setOpenDialog(true);
    const { amount, beneficiary, currency, pan } = formFields;
    const data = await createPayoutHandler(
      Number(amount),
      beneficiary,
      currency,
      pan,
      setIsLoading,
      setErrorMessage
    );

    if (data) {
      setResult(data);
      setDashboardData();
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3}>
      <Typography variant="h5" component="h2" flexGrow={1}>
        Форма
      </Typography>
      <Paper sx={{ padding: 4 }} component={'form'} onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          <TextField
            required
            id="amount"
            label="Сумма"
            name="amount"
            value={amount}
            onChange={changeFormField}
          />
          <TextField
            required
            id="beneficiary"
            label="Имя на карте"
            name="beneficiary"
            value={beneficiary}
            onChange={changeFormField}
          />
          <TextField
            required
            id="currency"
            label="Валюта"
            name="currency"
            value={currency}
            onChange={changeFormField}
          />
          <TextField
            required
            id="pan"
            label="Номер карты"
            name="pan"
            value={pan}
            onChange={changeFormField}
          />
        </Box>
        <LoadingButton
          sx={{ mt: 2 }}
          loading={isLoading}
          type="submit"
          color="secondary"
          variant="contained"
        >
          <span>Подтвердить</span>
        </LoadingButton>
        <PayoutDialog
          open={openDialog}
          closeDialog={closeDialog}
          isLoading={isLoading}
          errorMessage={errorMessage}
          result={result}
        />
      </Paper>
    </Box>
  );
};
