import axios from 'axios';

import { refreshTokenHandler } from '../handlers/authHandlers';

const API_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:3000';

export const authInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

export const apiInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

apiInstance.interceptors.request.use(
  async function (config) {
    await refreshTokenHandler();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/*apiInstance.interceptors.response.use(
  (response) => response, // Directly return successful responses.
  async (error) => {
    const originalRequest = error.config;
    if (!originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried to avoid infinite loops.
      try {
        await refreshTokenHandler();

        return apiInstance(originalRequest); // Retry the original request with the new access token.
      } catch (refreshError) {
        // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
        console.error('Token refresh failed:', refreshError);
        localStorage.removeItem('refresh_token');
        window.location.href = '/auth';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error); // For all other errors, return the error as is.
  }
);*/
