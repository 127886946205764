import { Alert, Box, Pagination, Paper, Typography } from '@mui/material';
import { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';

import { getExpensesHandler } from '../../app/handlers/terminalHandlers';
import { useExpensesStore } from '../../app/store/expensesStore';
import { useUserStore } from '../../app/store/userStore';
import { FilterBlock } from '../../components/Filters/FilterBlock';
import { TablePayments } from '../../components/Tables/TablePayments';

export const Expenses = () => {
  const [
    page,
    totalPages,
    transactions,
    setPage,
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    filters,
    setDateFrom,
    setDateTo
  ] = useExpensesStore((state) => [
    state.page,
    state.totalPages,
    state.transactions,
    state.setPage,
    state.isLoading,
    state.setIsLoading,
    state.errorMessage,
    state.setErrorMessage,
    state.filters,
    state.setDateFrom,
    state.setDateTo
  ]);
  const terminal_uuid = useUserStore((state) => state.terminal_uuid);

  useEffect(() => {
    getExpensesHandler(page, filters, setIsLoading, setErrorMessage);
  }, [page, filters, terminal_uuid]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value);
  };

  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value);
  };

  return (
    <Box flexGrow={1} display={'flex'} flexDirection={'column'}>
      <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
        Выплаты
      </Typography>
      <Paper sx={{ paddingY: 4 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2
          }}
        >
          <FilterBlock
            filters={filters}
            changeDateFrom={handleChangeDateFrom}
            changeDateTo={handleChangeDateTo}
          />
          {errorMessage && (
            <Alert severity="error" sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          )}
          {transactions.length > 0 ? (
            <>
              <TablePayments transactions={transactions} />
              <Pagination
                count={totalPages}
                variant="outlined"
                color="primary"
                onChange={handleChangePage}
              />
            </>
          ) : (
            <Alert severity={'info'} sx={{ width: '100%' }}>
              Ничего не найдено
            </Alert>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
