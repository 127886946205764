import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WithdrawalsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
        <path
          d="M9.75 7.5L14.25 3L18.75 7.5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 21C7.63695 21 9.92613 20.0518 11.614 18.364C13.3018 16.6761 14.25 14.3869 14.25 12V3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
