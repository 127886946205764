export default class RefreshToken {
  static setToken(token: string, remember: boolean) {
    if (remember) {
      localStorage.setItem('refresh_token', token);
    } else {
      sessionStorage.setItem('refresh_token', token);
    }
  }

  static getToken() {
    const token =
      localStorage.getItem('refresh_token') ||
      sessionStorage.getItem('refresh_token');

    return token;
  }

  static removeToken() {
    localStorage.removeItem('refresh_token');
    sessionStorage.removeItem('refresh_token');
  }
}
