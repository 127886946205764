import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';

import { ISidebarLink } from '../../app/configs/sidebarLinks';

interface Props {
  item: ISidebarLink;
}

export const SideLink = ({ item }: Props) => {
  const match = useMatch('main/' + item.to);

  return (
    <ListItem key={item.text} disableGutters>
      <ListItemButton
        component={NavLink}
        to={item.to}
        selected={!!match}
        color="inherit"
      >
        <ListItemIcon sx={{ zIndex: 2, color: 'inherit' }}>
          <item.Icon />
        </ListItemIcon>
        <ListItemText primary={item.text} sx={{ zIndex: 2 }} />
      </ListItemButton>
    </ListItem>
  );
};
