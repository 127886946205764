import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import React from 'react';

import { IOption } from '../../app/configs/roles';

interface Props {
  options: IOption[];
  value: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none' | undefined;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const CustomSelector = ({
  options,
  value,
  fullWidth,
  margin,
  required,
  error,
  disabled,
  onChange
}: Props) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as string);
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      required={required}
      error={error}
      disabled={disabled}
    >
      <InputLabel size="small">Роль</InputLabel>
      <Select value={value} label="Роль" onChange={handleChange} fullWidth>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
