import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React, { useState } from 'react';

import {
  getTeamMembersHandler,
  removeMemberFromTerminal
} from '../../app/handlers/terminalHandlers';
import { ITeamMember } from '../../app/models/response/terminal';

interface Props {
  member: ITeamMember;
  open: boolean;
  closeDialog: () => void;
}

export const DeleteMemberDialog = ({ member, open, closeDialog }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onHandleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = await removeMemberFromTerminal(
      member.email,
      member.role,
      setIsLoading,
      setErrorMessage
    );

    if (result) {
      await getTeamMembersHandler();
      closeDialog();
    }
  };

  const closeDialogWithClearError = () => {
    setErrorMessage('');
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialogWithClearError}
      PaperProps={{
        component: 'form',
        onSubmit: onHandleSubmit
      }}
    >
      <DialogTitle>Удаление из терминала</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <DialogContentText>
          Вы уверены что хотите удалить пользователя {member.email} из
          терминала?
        </DialogContentText>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'flex-start',
          paddingInline: 3,
          paddingBottom: 3
        }}
      >
        <LoadingButton
          loading={isLoading}
          color="secondary"
          variant="contained"
          type="submit"
          startIcon={<DeleteOutlinedIcon />}
        >
          <span>Удалить</span>
        </LoadingButton>
        <Button onClick={closeDialog}>Отмена</Button>
      </DialogActions>
    </Dialog>
  );
};
