import { Box, Drawer, List, Toolbar } from '@mui/material';
import React from 'react';

import { sidebarLinks } from '../../app/configs/sidebarLinks';
import { SideLink } from './SideLink';

const drawerWidth = 250;

export const Sidebar = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
    >
      <Toolbar />
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {sidebarLinks.map((item) => (
            <SideLink item={item} key={item.text} />
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
