import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const PaymentsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
        <path
          d="M9.75 16.5L14.25 21L18.75 16.5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 3C7.63695 3 9.92613 3.94821 11.614 5.63604C13.3018 7.32387 14.25 9.61305 14.25 12V21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
