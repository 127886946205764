import axios from 'axios';
import React from 'react';

import RefreshToken from '../helpers/refreshToken';
import { ISignInForm, ISignUpForm } from '../models/formFields';
import AuthService from '../services/authService';
import { useUserStore } from '../store/userStore';

const { addUser, setAuth } = useUserStore.getState();

export const signInHandler = async (
  formData: ISignInForm,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  setErrorMessage('');
  setIsLoading(true);

  try {
    const { data } = await AuthService.authenticate(
      formData.email,
      formData.password
    );

    RefreshToken.setToken(data.refresh_token, true);
    setAuth(true);
    addUser(formData.email);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.message);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const signUpHandler = async (
  formData: ISignUpForm,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  setErrorMessage('');
  setIsLoading(true);

  try {
    const { data } = await AuthService.register(
      formData.email,
      formData.password,
      formData.password_confirm,
      formData.phone
    );

    RefreshToken.setToken(data.refresh_token, true);
    setAuth(true);
    addUser(formData.email);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      setErrorMessage(error.response?.data.error);
    } else {
      console.log(error);
    }
  } finally {
    setIsLoading(false);
  }
};

export const refreshTokenHandler = async () => {
  try {
    const refresh_token = RefreshToken.getToken();
    const { data } = await AuthService.refreshToken(refresh_token || '');

    RefreshToken.setToken(data.refresh_token, true);
    setAuth(true);
  } catch (error) {
    setAuth(false);

    if (axios.isAxiosError(error)) {
      console.log(error.response?.data.error);
    } else {
      console.log(error);
    }
  }
};

export const logoutHandler = async () => {
  localStorage.removeItem('refresh_token');
  setAuth(false);
  addUser('');
};
