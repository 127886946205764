import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const ClientsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor">
        <path
          d="M8.25 15C10.9424 15 13.125 12.8174 13.125 10.125C13.125 7.43261 10.9424 5.25 8.25 5.25C5.55761 5.25 3.375 7.43261 3.375 10.125C3.375 12.8174 5.55761 15 8.25 15Z"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M14.5687 5.42813C14.9997 5.31124 15.4441 5.25136 15.8906 5.25C17.1836 5.25 18.4235 5.76361 19.3378 6.67785C20.252 7.59209 20.7656 8.83207 20.7656 10.125C20.7656 11.4179 20.252 12.6579 19.3378 13.5721C18.4235 14.4864 17.1836 15 15.8906 15"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 18.5062C2.2612 17.423 3.27191 16.5389 4.44676 15.9286C5.6216 15.3183 6.92608 14.9996 8.25 14.9996C9.57392 14.9996 10.8784 15.3183 12.0532 15.9286C13.2281 16.5389 14.2388 17.423 15 18.5062"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8906 15C17.2147 14.9992 18.5194 15.3174 19.6944 15.9277C20.8693 16.5381 21.8799 17.4225 22.6406 18.5063"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
