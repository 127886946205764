import { AccountCircle, Logout } from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { logoutHandler } from '../../app/handlers/authHandlers';
import { getTerminalsListHandler } from '../../app/handlers/terminalHandlers';
import { useUserStore } from '../../app/store/userStore';
import { ReactComponent as MpoLogo } from '../../assets/logoMPO.svg';
import { TerminalSelector } from '../Selectors/TerminalSelector';

export const Header = () => {
  const [terminals, changeTerminals, terminal_uuid] = useUserStore((state) => [
    state.terminals,
    state.changeTerminals,
    state.terminal_uuid
  ]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeTerminal = (value: string) => {
    changeTerminals(value);
    handleClose();
  };

  useEffect(() => {
    getTerminalsListHandler(setIsLoading, setError);
  }, []);

  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#FFF'
      }}
      elevation={0}
      position={'fixed'}
    >
      <Toolbar>
        <MpoLogo />
        <Box sx={{ flexGrow: 1 }}></Box>
        {terminals.length && (
          <TerminalSelector
            value={terminal_uuid}
            options={terminals}
            onChange={handleChangeTerminal}
          />
        )}

        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="primary"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/*<MenuItem>
              <TerminalSelector
                value={terminal_uuid}
                options={terminals}
                onChange={handleChangeTerminal}
                margin={'dense'}
              />
            </MenuItem>*/}
            <MenuItem onClick={logoutHandler}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Выйти
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};
