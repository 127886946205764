import {
  Box,
  Card,
  CardContent,
  Grid,
  SvgIcon,
  Typography
} from '@mui/material';
import React from 'react';

import { PaymentsIcon } from '../Icons';

interface Props {
  value: number;
  label: string;
  color?: string;
}

export const DashboardCard = ({ value, label, color }: Props) => {
  return (
    <Grid item sm={12} md={12} lg={6} xl={4}>
      <Card sx={{ borderRadius: 4, backgroundColor: color }}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              backgroundColor: '#FFF',
              height: 80,
              width: 80,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: 0
            }}
          >
            <SvgIcon sx={{ fontSize: 40 }}>
              <PaymentsIcon />
            </SvgIcon>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Box textAlign="end" sx={{ flexGrow: 1 }}>
              <Typography variant="h4" component="h4">
                {Number(value).toFixed(2)}
              </Typography>
              <Typography>{label}</Typography>
            </Box>
            {/*<IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>*/}
          </Box>
        </CardContent>
        {/*<Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button size="small" variant="text">
            Share
          </Button>
          <Button size="small" variant="text">
            Learn More
          </Button>
        </CardActions>*/}
      </Card>
    </Grid>
  );
};
