import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React from 'react';

import { formatDateTable } from '../../app/helpers/formatDateTable';
import { ITransaction } from '../../app/models/response/terminal';
import { StatusTable } from '../UI/StatusTable/StatusTable';

interface Props {
  transactions: ITransaction[];
}

export const TablePayments = ({ transactions }: Props) => {
  return (
    <TableContainer>
      <Table stickyHeader sx={{ minWidth: 900 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                paddingLeft: 4
              }}
            >
              ID
            </TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Сумма</TableCell>
            <TableCell align="right">Комиссия</TableCell>
            <TableCell align="right">total_amount</TableCell>
            <TableCell align="center">currency</TableCell>
            <TableCell sx={{ paddingRight: 4 }}>Статус</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row) => (
            <TableRow
              key={row.order_id}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell
                sx={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  paddingLeft: 4
                }}
              >
                {row.order_id}
              </TableCell>
              <TableCell>{formatDateTable(row.created_at)}</TableCell>
              <TableCell>{Number(row.amount).toFixed(2)}</TableCell>
              <TableCell align="right">
                {Number(row.fee_amount).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                {Number(row.total_amount).toFixed(2)}
              </TableCell>
              <TableCell align="center">{row.currency}</TableCell>
              <TableCell sx={{ paddingRight: 4 }}>
                <StatusTable status={row.status} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
