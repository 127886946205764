import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { ITerminal } from '../models/response/terminal';

type State = {
  isAuth: boolean;
  isTestMode: boolean;
  email: string;
  terminal_uuid: string;
  terminals: ITerminal[] | [];
  secret: string;
};

type Actions = {
  addUser: (email: string) => void;
  setAuth: (value: boolean) => void;
  setTestMode: (value: boolean) => void;
  setTerminals: (data: ITerminal[]) => void;
  changeTerminals: (value: string) => void;
  setSecret: (secret: string) => void;
  deleteUser: () => void;
};

const initialState: State = {
  isAuth: false,
  isTestMode: false,
  email: '',
  terminals: [],
  secret: '',
  terminal_uuid: '1dee9780-9f36-4ec9-b18f-0ce76d257337'
};

export const useUserStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setAuth: (value) => {
          set({ isAuth: value });
        },
        setTestMode: (value) => {
          set({ isTestMode: value });
        },
        addUser: (email) => {
          set({
            email: email
          });
        },
        setTerminals: (data) => {
          set({
            terminals: data
          });
          data.length && set({ terminal_uuid: data[0].uuid });
        },
        changeTerminals: (value) => {
          set({
            terminal_uuid: value
          });
        },
        setSecret: (secret) => {
          set({
            secret: secret
          });
        },
        deleteUser: () => {
          set(initialState);
          localStorage.removeItem('refresh_token');
        }
      }),
      { name: 'user' }
    ),
    { name: 'user' }
  )
);
