export const Paths = {
  APP: '/',
  // Authentication
  AUTH: '/auth',
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
  RESET_PASSWORD: 'reset-password',
  // Main app
  MAIN: '/main',
  DASHBOARD: 'dashboard',
  INCOMES: 'incomes',
  EXPENSES: 'expenses',
  CLIENTS: 'clients',
  PROFILE: 'profile',
  // Other
  NOT_FOUND: '*'
} as const;
