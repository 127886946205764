import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  text: string;
}

export const AutohideSnackbar = ({ open, setOpen, text }: Props) => {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        message={text}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </div>
  );
};
