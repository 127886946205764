import { ITransactionStatus } from '../models/response/terminal';

export const statusTypes = new Map([
  [
    ITransactionStatus.FAILED,
    {
      label: 'Отклонен',
      value: ITransactionStatus.FAILED
    }
  ],
  [
    ITransactionStatus.SUCCESS,
    {
      label: 'Принят',
      value: ITransactionStatus.SUCCESS
    }
  ],
  [
    ITransactionStatus.PENDING,
    {
      label: 'Обработка',
      value: ITransactionStatus.PENDING
    }
  ]
]);
