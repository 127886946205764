export enum Roles {
  OWNER = 'owner',
  ADMINISTRATOR = 'administrator',
  DEVELOPER = 'developer',
  ACCOUNTANT = 'accountant'
}

export interface IOption {
  label: string;
  value: string;
}

export const rolesOptions: IOption[] = [
  { value: Roles.ACCOUNTANT, label: 'Бухгалтер' },
  { value: Roles.DEVELOPER, label: 'Разработчик' },
  { value: Roles.ADMINISTRATOR, label: 'Администратор' },
  { value: Roles.OWNER, label: 'Владелец' }
];
